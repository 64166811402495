<template>
  <div>
    <div
      v-if="isDownloading"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-70"
    >
      <button class=" btn  btn-xl btn-circle btn-ghost loading" />
    </div>
    <div
      v-if="expired"
      class="flex items-center justify-center flex-auto text-center h-screen"
    >
      <h5>
        Expired
      </h5>
    </div>
    <div
      v-else
    >
      <div
        v-if="!pdf"
        class="flex justify-between p-2"
      >
        <div class="flex">
          <router-link :to="isTeam ? `/teams/${campaignId}/scoreboard` : { name: 'Scoreboard', params: { campaignId, page: 0 } }">
            <div class="flex items-center text-sm font-semibold">
              <svg
                width="28"
                height="17"
                viewBox="0 0 33 22"
                fill="none"
                class="mr-3"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.458 10.9454L1 10.9454"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.999842 10.9437L10.9453 20.8892"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.999841 10.9454L10.9453 0.999963"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Scoreboard
            </div>
          </router-link>
        </div>
        <div

          class="dropdown dropdown-bottom dropdown-end btn-primary"
          style="padding: 1rem !important;"
        >
          <label
            tabindex="0"
            class="cursor-pointer text-white flex items-center text-sm font-normal"
          >   <svg
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            stroke-width="1.2"
            class="mr-4"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z"
            />
          </svg>
            More</label>
          <ul
            tabindex="0"
            class="p-4 shadow dropdown-content menu bg-base-100 rounded-box w-96 border border-gray-300 text-black"
          >
            <li
              @click="downloadReport(false)"
              class="text-sm text-left leading-snug"
            >
              <a><svg
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="mr-2 w-7 h-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>
                Download report</a>
            </li>
            <li
              @click="downloadReport(true)"
              class="text-sm text-left leading-snug"
            >
              <a><div>
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="mr-2 w-7 h-7"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                  />
                </svg>
              </div>
                Download report (anonymized)</a>
            </li>
            <li
              @click="showShareModal = true"
              class="text-sm text-left leading-snug"
            >
              <a><div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="mr-2 w-7 h-7"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </div>
                Share with email</a>
            </li>
          </ul>
        </div>
      </div>
      <executive-summary-page
        :anonymized="anonymized"
        :pdf="pdf"
        :chart-data="mainMeasures"
        v-if="loadedData"
      />
      <share-modal
        :open="showShareModal"
        :candidate="mainCandidate"
        @close="showShareModal = false"
        :campaign-id="campaignId"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import ExecutiveSummaryPage from '../../AppReport/components/ExecutiveSummaryPage.vue'
import download from 'downloadjs'
import ShareModal from '@/modules/AppReport/components/ShareModal.vue'

export default {
  components: {
    ExecutiveSummaryPage,
    ShareModal
  },

  props: {
    userId: {
      type: String,
      required: true
    },
    campaignId: {
      type: String,
      required: false,
      default: null
    },
    pdf: {
      type: Boolean,
      required: false,
      default: false
    },
    anonymized: {
      type: Boolean,
      required: false,
      default: true
    },
    isTeam: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      expired: false,
      showCheckoutInfo: false,
      hostedPage: null,
      loadedData: false,
      isDownloading: false,
      mainCandidateName: null,
      showShareModal: false
      // showDownloadButton: true
    }
  },

  async created () {
    const res = await this.$store.dispatch('REPORT/getResults', { id: this.userId, campaignId: this.campaignId, summary: true })

    if (res instanceof Error) {
      this.expired = true
    } else if (res.message === 'needs_checkout' && !res.hostedPage) {
      this.expired = true
    } else {
      await this.$store.dispatch('SCOREBOARD/getCandidatesTestIssues', this.campaignId)
    }

    this.loadedData = true
  },
  computed: {
    ...mapGetters({
      mainMeasures: 'REPORT/getMainMeasures',
      mainCandidate: 'REPORT/getMainCandidate'
    })
  },
  methods: {
    async downloadReport (anonymized) {
      this.isDownloading = true
      const { data, headers } = await this.$api.getPdfExecSummary(this.userId, this.campaignId, anonymized)
      const contentType = headers['content-type']
      download(data, `Executive Summary ${anonymized ? `Candidate ${this.mainCandidate.alt_id}` : this.mainCandidate.name}`, contentType)

      this.isDownloading = false
    }
  },
  destroyed () {
    this.$store.dispatch('REPORT/empty')
  }
}
</script>

<style lang="scss">@import '../../AppReport/report.scss';
div.flex.flex-row.justify-between.m-0 > div {
  height: auto;
}
</style>
